<template>
  <v-card>
    <v-card-title>{{ $t('menu.translate') }} / {{ $t('lbl.leng') }}</v-card-title>
    <v-divider> </v-divider>
    <v-card-text>
      <v-row v-if="idiomas.length > 0">
        <v-col
          cols="12"
          md="12"
        >
          <IdiomaOne
            v-for="(language, indLeng) in idiomas"
            :key="indLeng"
            :language="language"
            :pos="indLeng"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiDotsVertical,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import IdiomaOne from '@/views/configuration/identificador/utils/IdiomaOne.vue'

export default {
  components: {
    IdiomaOne,
  },
  data() {
    return {
      idiomas: [],
      isDialogExport: false,
      languajeBase: null,
      languajeOther: null,
      languajes: [],
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      cant_sin_trans: 0,
    }
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
  },
  created() {
    this.fetchDataFromEndpoints()
  },
  methods: {
    ...mapMutations(['setLanguages']),
    async fetchDataFromEndpoints() {
      try {
        const [
          resItems,
        ] = await Promise.all([
          this.axios
            .post('languages/list', {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resItems.data.success === false) { } else {
          this.idiomas = resItems.data.data.data
          const arr = []
          this.idiomas.forEach(element => {
            if (element.active) {
              arr.push(element)
            }
          })

          this.setLanguages(arr)
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
