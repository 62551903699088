<template>
  <fragment>
    <v-row>
      <v-col
        cols="3"
      >
        <v-text-field
          v-model="language.name"
          :label="$t('lbl.name')"
          outlined
          dense
          hide-details="auto"
          disabled
        >
          <template v-slot:prepend>
            <country-flag
              :country="language.locale === 'en' ? 'gb' : language.locale"
              size="normal"
            />
          </template>
        </v-text-field>
      </v-col>

      <v-col
        v-if="language.locale !== 'es'"
        cols="12"
        md="3"
      >
        <v-switch
          v-model="language.active"
          :label="$t('lbl.active')"
          hide-details
          class="mt-0"
          :disabled="language.locale === $i18n.locale"
          @click="setActive"
        ></v-switch>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    language: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      rols: [],
      products: [],
      emailRules: [v => this.isEmail(v) || 'Email debe ser válido'],
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
    }),
  },
  methods: {
    ...mapMutations(['setLanguages']),
    async setActive() {
      await this.axios
        .post('languages/update', this.language, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.setLanguages(res.data.data.data)
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
    },
  },
}
</script>
<style scoped>
v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer {
  margin-top: 0px !important;
}
.normal-flag {
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
